<div class="gd-image-download {{ image.orientation }}"
     fxLayout="column"
     fxLayoutAlign="space-between"
     [dir]="dir$ | async">
  <h2 style="margin: 0 0 20px;" i18n="Image renditions modal title" i18n>Image renditions</h2>
  <div fxLayout="row"
       fxLayoutGap="24px"
       fxLayoutAlign="center">

    <div fxFlex="35%"
         fxLayoutAlign="center center"
         class="gd-image-download__image-wrapper">
      <div *ngIf="imageSrc"
           class="gd-image-download__image {{ image.orientation }}"
           [ngStyle]="{'background-image': 'url(' + imageSrc + ')'}"
           [class.--icon]="isScaleDownRequired(image)">
      </div>
      <div *ngIf="!imageSrc"
           class="gd-image-download__image-placholder">
        <mat-spinner color="accent"
                     diameter="55">
        </mat-spinner>
      </div>

    </div>

    <div fxFlex="65%">
        <div class="gd-image-download__image-renditions gd-image-download__renditions-scroller">
          <gd-renditions-view [renditions]="image.formats" (previewChange)="updatePreview($event)"></gd-renditions-view>
        </div>
    </div>

  </div>

  <div class="gd-image-download__actions"
       fxLayout="row"
       fxLayoutAlign="flex-end"
       fxLayoutGap="12px">
    <button mat-button
            mat-raised-button
            mat-dialog-close
            i18n="Image download modal button"
            i18n>
      Cancel
    </button>
  </div>
</div>
