
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of } from 'rxjs';
import { UserPreferencesService } from '../user-preferences/user-preferences.service';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class FilesService {
  defaultParams = {
    service: 'glideMedia',
  };

  constructor(
    private rest: RestService,
    private userPreferenceService: UserPreferencesService,
    private accountSettingsService: AccountSettingsService,
    private snackBar: MatSnackBar
  ) {}

  getFiles(payload) {
    const requestUrl = makeRequestUrl(payload);
    return this.rest.get(requestUrl, this.defaultParams);
  }

  // get file and folder by path
  getFile(path) {
    const formatedPath = path.substring(1).replace(/\//g, ',');
    return this.rest.get('files/edit/' + formatedPath, { service: 'glideMedia' }).toPromise();
  }

  getFileById(id, type = null) {
    let path = `files/${id}`;
    path += type ? `?type=${type}` : '';
    return this.rest.get(path, { service: 'glideMedia' }).toPromise();
  }

  createFolder(payload) {
    return this.rest.post('folders', payload, this.defaultParams);
  }

  deleteFolder(folderId) {
    return this.rest.delete(`folders/${folderId}`, { service: 'glideMedia' });
  }

  deleteFile(fileId) {
    return this.rest.delete(`files/${fileId}`, { service: 'glideMedia' });
  }

  updateFileDetails({ fileId, fileData }) {
    return this.rest.put(`files/${fileId}`, fileData, this.defaultParams);
  }

  updateFolderDetails({ folderId, folderData } ) {
    return this.rest.put(`folders/${folderId}`, folderData, this.defaultParams);
  }

  getFileAsCollectionItem(fileId) {
    return this.rest.get(`files/${fileId}`, { service: 'glideMedia' }).pipe(
      mergeMap(file => file.error ? of(null) : of(file)));
  }

   resolveEmbedFileThumbnail (type, source = 'media') {
    const theme = this.userPreferenceService.getUserPreference('theme');
    const fileIconPath = source === 'media'
                                            ? theme !== 'default'
                                                          ? 'files/dark'
                                                          : 'files'
                                            : 'collection';
    switch (type) {
      case 'pdf':
        return `assets/img/${fileIconPath}/pdf.svg`;
      case 'doc':
      case 'docx':
        return `assets/img/${fileIconPath}/doc.svg`;
      case 'mp4':
      case 'm4v':
      case 'avi':
      case 'webm':
      case 'wmv':
      case 'mov':
        return `assets/img/${fileIconPath}/video.svg`;
      case 'ogg':
      case 'wav':
      case 'mp3':
          return `assets/img/${fileIconPath}/sound.svg`;
      case 'xls':
      case 'xlsx':
        return `assets/img/${fileIconPath}/xls.svg`;
      case 'ppt':
      case 'pptx':
        return `assets/img/${fileIconPath}/ppt.svg`;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'ico':
      case 'webp':
        return `assets/img/${fileIconPath}/image.svg`;
      case 'svg':
        return `assets/img/${fileIconPath}/svg.svg`;
      case 'txt':
        return `assets/img/${fileIconPath}/txt.svg`;
      case 'rtf':
        return `assets/img/${fileIconPath}/rtf.svg`;
      default:
        return `assets/img/${fileIconPath}/default.svg`;
    }

   }
   resolveFileThumbnail(type, source = 'media') {
    const isMediaFile = source === 'media';
    switch (type) {
      case 'pdf':
        return 'fa-file-pdf';
      case 'doc':
      case 'docx':
        return `fa-file-word`;
      case 'mp4':
      case 'm4v':
      case 'avi':
      case 'mkv':
      case 'webm':
      case 'wmv':
      case 'mov':
        return `fa-file-video`;
      case 'ogg':
      case 'wav':
      case 'mp3':
          return `fa-file-audio`;
      case 'xls':
      case 'xlsx':
        return `fa-file-excel`;
      case 'ppt':
      case 'pptx':
        return `fa-file-powerpoint`;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'tiff':
      case 'gif':
      case 'ico':
      case 'webp':
        return `fa-file-image`;
      case 'svg':
        return `glideCustomIcon_file-svg`;
      case 'txt':
        return `fa-file-alt`;
      case 'rtf':
        return 'glideCustomIcon_file-rtf';
      default:
        return 'fa-file';
    }
  }

  resolveFileSize(fileSize) {
    const size = +fileSize;
    if (size < 1024) {
      return `${size} bytes`;
    } else if (size >= 1024 && size < 1048576) {
      return `${(size / 1024).toFixed(1)} KB`;
    } else if (size >= 1048576) {
      return `${(size / 1048576).toFixed(1)} MB`;
    }
  }

  generateFilePreviewUrl(path) {
    if (!path) {
      return '';
    }
    const mediaS3BaseUrl = this.accountSettingsService.getMediaS3BaseUrl();
    return mediaS3BaseUrl + path;
  }

  downloadFile(key, filename) {
    const url = this.generateFilePreviewUrl(key);
    fetch(url, { mode: 'cors', cache: "reload" })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log('Download Error:', e);
        this.snackBar.open($localize`Failed to download file.`, $localize`Close`, {
          duration: 5000,
        });
      });
  }
}

function makeRequestUrl({ folderPath, searchName, searchOwner, searchDateFrom, searchDateTo, userIds, excludeFiles = false, pageIndex = 0, pageSize = 50 }) {
  const queryParams = [];
  if (folderPath) {
    queryParams.push(`folder=${folderPath.substring(1).replace(/\//g, ',')}`);
  }

  if (searchName) {
    queryParams.push(`name=${searchName}`);
  }

  if (searchOwner) {
    queryParams.push(`searchOwner=${searchOwner}`);
  }

  if (searchDateFrom) {
    queryParams.push(`dateFrom=${searchDateFrom}`);
  }

  if (searchDateTo) {
    queryParams.push(`dateTo=${searchDateTo}`);
  }

  if (userIds && userIds.length) {
    queryParams.push(`userIds=${userIds.join(',')}`);
  }

  if (excludeFiles) {
    queryParams.push('excludeFiles=true');
  }

  pageIndex = +pageIndex || 0;
  pageSize = +pageSize || 50;
  queryParams.push(`page=${pageIndex}`);
  queryParams.push(`size=${pageSize}`);

  return 'files?' + queryParams.join('&');
}
