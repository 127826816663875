import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as shortid from 'shortid';

export function generateShortId() {
  return 'e' + shortid.generate();
}

export function highlightSearchTerm(item, filterValue, type) {
  // do initial placeholder assign. placeholders are used for display so they must be set
  const name = item.headline || item.name;
  item.headlinePlaceholder = name;
  item.catchlinePlaceholder = item.catchline || '';

  const inputTerm = filterValue?.headline || filterValue;

  // if there filter input is an empty string or not a string, exit here, no highlighting
  if(!inputTerm || typeof inputTerm !== 'string') {
    return;
  }

  // since we do full text search individual words can be matched separately
  // so we split the term by whitespace and each fragment can be highlighted separately
  const compositeTerm = inputTerm ? inputTerm.split(/\s+/g).join('|') : '';
  const regex = new RegExp(compositeTerm, 'gi');


  let headlineMatch = name && name.match(regex);
  if (headlineMatch) {
    headlineMatch = headlineMatch.filter((el, i, a) => i === a.indexOf(el));
    headlineMatch.forEach(match => item.headlinePlaceholder = item.headlinePlaceholder.replace(match, '<b>' + match + '</b>'));
  }

  let catchlineMatch = item.catchline && item.catchline.match(regex) || null;
  if (catchlineMatch) {
    catchlineMatch = catchlineMatch.filter((el, i, a) => i === a.indexOf(el));
    catchlineMatch.forEach(match => item.catchlinePlaceholder = item.catchlinePlaceholder.replace(match, '<b>' + match + '</b>'));
  }

}

// Function used for filtering available Menus, Pages and Templates.
export function filterActiveItems(items, itemId, keyId, keyParentId) {
  if (items && items.length === 0) {
    return [];
  }

  const itemsObject = items.reduce((obj, item) => {
    obj[item[keyId]] = item;
    return obj;
    }, {});

  const availableItems = [];
  Object.keys(itemsObject).forEach(key => {
    if (itemsObject[key][keyParentId] === itemId || itemsObject[key][keyId] === itemId) {
      return;
    }
    if (!itemsObject[key][keyParentId]) {
       return availableItems.push(itemsObject[key]);
    }
    const isAvailable = isItemsParentAvailable(itemsObject, itemsObject[key][keyParentId], keyParentId, itemId);

    if (isAvailable) {
      return availableItems.push(itemsObject[key]);
    }
  });
  return availableItems;
}

export function isItemsParentAvailable(itemsObject, itemParentId, keyParentId, itemId) {
  if (!itemsObject[itemParentId][keyParentId]) {
    return true;
  }
  if (itemsObject[itemParentId][keyParentId] === itemId) {
    return false;
  }
  return isItemsParentAvailable(itemsObject, itemsObject[itemParentId][keyParentId], keyParentId, itemId);
}

export function typecastAbstractToFormControl(absCtrl: AbstractControl): UntypedFormControl {
  return absCtrl as UntypedFormControl;
}

export function typecastAbstractToFormGroup(absCtrl: AbstractControl): UntypedFormGroup {
  return absCtrl as UntypedFormGroup;
}

export function typecastAbstractToFormArray(absCtrl: AbstractControl): UntypedFormArray {
  return absCtrl as UntypedFormArray;
}

export function pauseExecution(timeInMills) {
  return new Promise(res => setTimeout(() => res(true), timeInMills));
}

export function clearFormArray(formArray: UntypedFormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0)
  }
}

export function getSEOCompatibleText(text, allowedCharacters, trailingDashAllowed = false) {
  const dictionary = {
    // Đ is not supported so we are adding it into dictionary
    Đ: 'dj',
    đ: 'dj',
    // these characters are creating empty space and in the slug we have "-" character
    // i.e "ČČ test" would be converted into "c-c-test" but we want the result to be "cc-test"
    // so the dictionary below is used for that purpose
    Æ: 'a',
    æ: 'a',
    ð: 'e',
    Ø: 'o',
    ø: 'o',
    Œ: 'o',
    œ: 'o',
    ß: 's',
  };
  let output = text
    // normalize english alphabet chars with accents/diacritics
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    // normalize/replace non-english alphabet chars
    .replace(/[ĐđÆæðØøŒœß]/g, (m) =>  dictionary[m]);

  // if allowed url chars do not allow uppercase characters - convert everything to lowercase
  if (!allowedCharacters.includes('A-Z')) {
    output = output.toLowerCase();
  }
  const regExp = new RegExp(`[^${allowedCharacters}]`, 'g');
  // Steps:
  // - convert multiple whitespace to single hyphen
  // - remove everything that is not allowed url characters
  // - convert multiple hyphen to single hyphen
  // - remove leading dashes
  output = output.replace(/[\s]+/g, '-').replace(regExp, '').replace(/[-]+/g, '-').replace(/^-+/g, '');

  if (!trailingDashAllowed) {
    // - remove trailing dashes
    output = output.replace(/-+$/g, '');
  }
  return output;
}

export function normalizeString(str) {
  return decodeHtmlEntities(str)
    .replace(/<\/?p>/g, ' ') // Replace <p> and </p> with a space
    .replace(/<[^>]*>/g, '') // Remove other HTML tags
    .replace(/&nbsp;/g, ' ') // Replace &nbsp; with a space
    .replace(/\n+/g, ' ') // Replace one or more newlines with a single space
    .replace(/\s+/g, ' ') // Normalize multiple spaces to a single space
    .trim();
}

// used to translate special characters letters
// e.g. without this 'Λ' will translate to 'Lambda;' or 'Ξ' to '&Xi;' in HTML field
function decodeHtmlEntities(str) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}