import { ContentModel, ContentNode } from '../article/article-content.model';

export interface Taxonomy {
  id: number;
  name: string;
}

export interface Author {
  id: number;
  name: string;
}

export interface Image {
  id: string;
  path: string;
}

export interface PostRevision {
  id: number;
  catchline: string;
  headline: string;
  headlineHtml: string;
  taxonomies: Taxonomy[];
  primaryTaxonomy: Taxonomy;
  authors: Author[];
  image: Image;
  body: ContentModel;
  publishFrom: number | string;
  publishTo: number | string;
}

export interface Post {
  id: number;
  glideId: string;
  createdAt: number | string;
  updatedAt: number | string;
  firstPublishedAt: number | string;
  lastPublishedAt: number | string;
  createdBy: number;
  updatedBy: number;
  firstPublishedBy: number;
  lastPublishedBy: number;
  workingRevision: PostRevision;
  publishedRevision: PostRevision;
  lockedByUserId: number;
}

export interface PageView {
  pageIndex: number;
  pageSize: number;
  total: number;
}

export interface PostsState {
  posts: Post[];
  postListReferencedContent: {};
  loaded: boolean;
  loading: boolean;
  error: string;
  activePost: Post | null;
  editorContentModel: ContentModel;
  pageView: PageView;
}

export function getPostsInitialState(): PostsState {
  return {
    posts: [],
    postListReferencedContent: {},
    loaded: false,
    loading: false,
    error: null,
    activePost: null,
    editorContentModel: {
      bodyHtml: '',
      contentNodes: {},
      contentStructure: [],
      referencedContent: {}
    },
    pageView: { pageIndex: 0, pageSize: 0, total: 0 }
  }
}
