<!-- [class.gd-images-view-grid__thumbnail--gallery-placeholder]="item.thu" -->
<div
  [dir]="dir$ | async"
  class="gd-images-view-grid__thumbnail"
  [class.loaded]="(item.thumbnailLoaded || item.thumbnail) && imageThumbnailSrcAvailable"
  [class.--gallery]="config && config.showGalleryIcon"
  [class.--files]="config && config.view === 'files'"
  [class.--images-list]="config && config.listView"
  [class.--modal]="config && config.embedImageModal"
  [style.border]="
    (loading && !item.thumbnailLoaded) || !item.thumbnail || !imageThumbnailSrcAvailable
      ? 'solid 1px #DCDCDC'
      : 'none'
  "
  [ngStyle]="thumbnailProps"
  [class.mat-elevation-z4]="isSelected"
>
  <!-- Thumbnail image  -->
  <img
    #thumbnailImage
    *ngIf="config.view !== 'files' && (imageThumbnailSrcAvailable && item.thumbnail)"
    id="image-thumb-{{ item.id }}"
    class="gd-images-view-grid__thumbnail-img {{ '--' + item.orientation }} {{
      config && config.listView ? '--list-thumbnail' : ''
    }}"
    [attr.queue-id]="item.queueID"
    data-cy="gd-image-thumbnail-view-grid"
    [class.--modal]="config && config.embedImageModal"
    [src]="(config && config.embedImageModal ? item.previewImage : item.thumbnail) || ''"
  />
  <mat-chip class="gd-images-view-grid__image-chip" *ngIf="config.viewAs !== 'gallery-images' && item.premiumImageTag"
    [class.--premium-chip-list-modal-view]="(!viewGridActive || config.embedImageModal)"
    [class.--premium-chip-grid-view]="viewGridActive && !config.embedImageModal"
    [matTooltip]="item.premiumImageTag.name">
    {{ item.premiumImageTag.name }}
  </mat-chip>
  <mat-icon *ngIf="config.view === 'files' && !item.fileData.isSvgIcon" class="gd-images-view-grid__thumbnail-file-icon" fontSet="fal" fontIcon="{{ resolveThumbnailImg(item) }}"></mat-icon>
  <mat-icon *ngIf="config.view === 'files' && item.fileData.isSvgIcon" class="gd-images-view-grid__thumbnail-file-svg-icon" svgIcon="{{ resolveThumbnailImg(item) }}"></mat-icon>

  <!-- Indicator icons -->
  <!-- Image Selected -->
  <mat-icon *ngIf="config && config.enableSelection && isSelected" class="checked-icon">
    check_circle
  </mat-icon>

  <!-- Gallery placeholder icon -->
  <mat-icon *ngIf="config && config.showGalleryIcon" class="gallery-icon" svgIcon="glideCustomIcon_galleries">
  </mat-icon>

  <section *ngIf="config.showGalleryIcon && config.showUnpublishedContent">
    <mat-chip *ngIf="item.status"
              class="gd-image-thumbnail__status-icon"
              [ngStyle]="getStatusChipStyle(item.status)">{{item.status.name}}</mat-chip>
  </section>

  <!-- Image being processed indicator -->
  <mat-icon *ngIf="imageBeingProcessed | async" class="processing-icon">
    schedule
  </mat-icon>

  <!-- Thumbnail overlays for idle state -->
  <div
    class="gd-images-view-grid__thumbnail-overlay"
    [class.--active]="
      ((item.processing || item.uploading || item.processed) && (uploadQueueActive | async)) ||
      item.invalid
    "
    [class.--upload-page]="config.showUploadActionControls && !item.invalid"
    *ngIf="
      config &&
      (config.showActionControls || config.showUploadActionControls || config.showListViewActions)
    "
  >
    <!-- Queue idle ie. Queue isnt in upload process -->
    <div *ngIf="(uploadQueueActive | async) !== true" style="height: 100%">
      <!-- Image List Action Icons -->
      <div
        class="gd-images-view-grid__thumbnail-overlay--action-icons"
        *ngIf="config && (config.showActionControls && !config.enableSelection)"
        fxLayout="column"
      >
        <div fxFlex="60%" fxLayoutAlign="center center">
          <span
            class="zoom-icon"
            fxFlex="100%"
            fxLayoutAlign="center center"
            (click)="preview.emit(item)"
            style="height: 100%"
          >
            <mat-icon fontSet="fal" fontIcon="fa-search-plus"></mat-icon>
          </span>
        </div>
        <div fxFlex="40%" fxLayout="row" fxLayoutAlign="space-evenly center">
          <span (click)="edit.emit(item)" *ngIf="hasActionPermission">
            <mat-icon fontSet="fal" fontIcon="fa-edit"></mat-icon>
          </span>
          <span (click)="download.emit(item)">
            <mat-icon svgIcon="glideCustomIcon-image-renditions"></mat-icon>
          </span>
          <span
            *ngIf="
              hasDeletePermission &&
              config &&
              (config.showActionControls && !config.canRemoveFromList)
            "
            (click)="deleteImage(item)"
            class="gd-images-view-grid__thumbnail-delete-span"
            [class.--disabled]="loading && item.id === deletedImageId"
          >
          <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
          </span>
          <span
            *ngIf="
              hasDeletePermission &&
              config &&
              (config.showActionControls && config.canRemoveFromList)
            "
            (click)="remove.emit(item)"
          >
          <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
          </span>
        </div>
      </div>

      <!-- Upload Actions -->
      <div
        class="gd-images-view-grid__thumbnail-overlay--action-icons"
        *ngIf="config && config.showUploadActionControls"
        fxLayout="column"
      >
        <div fxFlex="60%" fxLayoutAlign="center center"></div>
        <div
          class="gd-images-view-grid__thumbnail-overlay--action-icons-buttons"
          fxFlex="40%"
          fxLayout="row"
          fxLayoutAlign="space-evenly center"
        >
          <span
            data-cy="gd-edit-image-meta"
            (click)="config.view !== 'files' ? addMeta.emit(item) : editFile.emit(item)"
            [class.disabled]="item.invalid"
          >
            <mat-icon fontSet="fal" fontIcon="fa-edit"></mat-icon>
          </span>
          <span
            *ngIf="config && config.view !== 'files'"
            [class.disabled]="item.invalid || ((hasCroppableRenditions$ | async) !== true)"
            matTooltip="There are currently no renditions available for Manual Crop. This can be configured in the 'Media > Configure' section"
            i18n-matTooltip
            [matTooltipDisabled]="hasCroppableRenditions$ | async"
            (click)="triggerCroppingProcess()"
            style="pointer-events: all;">
            <mat-icon>crop</mat-icon>
          </span>
          <span (click)="remove.emit(item)">
            <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
          </span>
        </div>

        <div class="gd-images-view-grid__thumbnail-overlay--error" *ngIf="item.invalid">
          <div fxFlex="1 0 50%">
            <mat-icon class="info-icon"
                      fontSet="fal"
                      fontIcon="fa-info-circle"></mat-icon>
          </div>
          <div fxFlex="1 2 25%" *ngIf="!item.allowedType" i18n>File type not allowed</div>
          <div fxFlex="1 2 25%" *ngIf="item.exceedsLimit" i18n>File size limit exceeded</div>
        </div>
      </div>

      <!-- List View Actions -->
      <div
        *ngIf="config && config.showListViewActions"
        class="gd-images-view-grid__thumbnail-overlay--action-icons"
        fxLayout="column"
      >
        <span class="zoom-icon" fxFlex="100%" fxLayoutAlign="center center" style="height: 100%">
          <mat-icon fontSet="fal" fontIcon="fa-search-plus"></mat-icon>
        </span>
      </div>
    </div>

    <!-- Active Queue ie Upload queue is processing -->
    <div *ngIf="uploadQueueActive | async" style="height: 100%">
      <!-- Progress overlay/indicator -->
      <div
        class="gd-image-thumbnail-progress__overlay"
        *ngIf="config && config.showProgressOverlay"
      >
        <mat-progress-spinner
          class="gd-image-thumbnail-progress"
          *ngIf="(!item.uploaded && item.uploading)"
          color="accent"
          diameter="55"
          [mode]="item.processing ? 'indeterminate' : 'determinate'"
          [value]="item.uploadProgress"
        >
        </mat-progress-spinner>

        <mat-icon
          class="gd-image-thumbnail-progress__check-icon"
          *ngIf="item.uploaded"
        >
          check_circle
        </mat-icon>
      </div>
    </div>
  </div>
</div>
