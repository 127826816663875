import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ImagesService } from '../../core/api/images/images.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-renditions-view',
  templateUrl: './renditions-view.component.html',
  styleUrls: ['./renditions-view.component.scss']
})
export class RenditionsViewComponent implements OnInit {

  @Input() renditions;
  displayData;
  @Output() previewChange = new EventEmitter<string>();

  actionType; // actionType = 'COPY' | 'DOWNLOAD'
  formatMap = {
    'jpg': 'JPG',
    'jpeg': 'JPEG',
    'png': 'PNG',
    'webp': 'WebP',
    'gif': 'GIF',
    'avif': 'AVIF',
  };

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private snackBar: MatSnackBar,
    private imagesService: ImagesService,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.displayData = this.renditions.map(({ label, width, height, key, formats }) => {
      if (!formats) {
        const extension = key.slice(key.lastIndexOf('.') + 1);
        formats = { [extension]: key };
      }
      const availableFormats = Object.keys(formats).reduce((acc, item) => {
        return [
          ...acc,
          { key: item, label: this.formatMap[item], link: this.imagesService.generateImageUrl(formats[item]), s3Link: this.imagesService.generateImageS3Url(formats[item]) }
        ];
      }, []);

      return {
        title: `${label} (${width}x${height})`,
        availableFormats,
        availableFormatsInfo: availableFormats.map(format => format.label).join(', ')
      };
    });
  }

  handleAction(format, action = null) {
    this.actionType = action || this.actionType;
    if (this.actionType === 'COPY') {
      return this.handleCopyImageLink(format.link);
    }
    if (this.actionType === 'OPEN_NEW_TAB') {
      return this.openNewTab(format.link);
    }
    if (this.actionType === 'DOWNLOAD_IMAGE') {
      return this.handleDownloadImage(format.s3Link)
    }
    if (this.actionType === 'PREVIEW_IMAGE') {
      return this.handlePreviewImage(format.link)

    }
  }

  handleCopyImageLink(link) {
    const input = document.createElement('input');
    input.value = link;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.snackBar.open($localize`Copied to clipboard`, $localize`Close`, { duration: 3000 });
  }

  openNewTab(link) {
    const linkChunks = link.split('/');
    const fileName = linkChunks[linkChunks.length - 1];
    const linkToVisit = document.createElement('a');
    linkToVisit.target = '_blank';
    linkToVisit.href = link;
    linkToVisit.download = fileName;
    linkToVisit.style.display = 'none';
    document.body.appendChild(linkToVisit);
    linkToVisit.click();
    document.body.removeChild(linkToVisit);
  }
 
  handleDownloadImage(link) {
    fetch(link, { mode: 'cors', cache: "reload" })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        const filename = link.match(/[^/]+$/);
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) =>
        this.snackBar.open($localize`Failed to download image.`, $localize`Close`, {
          duration: 5000,
        })
      );
  }

  handlePreviewImage(link) {
    this.previewChange.emit(link);
  }
}
