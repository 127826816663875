<div class="gd-renditions-view__rendition-wrapper"
     *ngFor="let rendition of displayData"
     [dir]="dir$ | async">
  <div fxFlex="auto"
       fxLayout="column">
    <span class="gd-renditions-view__rendition-title">{{ rendition.title }}</span>
    <span class="gd-renditions-view__available-formats">
      <ng-container i18n>Available formats: </ng-container>{{ rendition.availableFormatsInfo }}
    </span>
  </div>
  <div class="gd-renditions-view__rendition-actions">
    <!-- a case when mat-menu IS NOT needed (one format available) -->
    <ng-container *ngIf="rendition.availableFormats.length < 2">
      <mat-icon matTooltip="Preview image renditions" i18n-matTooltip fontSet="fal" fontIcon="fa-eye" (click)="handleAction(rendition.availableFormats[0], 'PREVIEW_IMAGE')"></mat-icon>
      <mat-icon matTooltip="Download image renditions" i18n-matTooltip fontSet="fal" fontIcon="fa-cloud-download" (click)="handleAction(rendition.availableFormats[0], 'DOWNLOAD_IMAGE')"></mat-icon>
      <mat-icon matTooltip="Copy image source"
                i18n-matTooltip="copyImageRenditionURL|"
                (click)="handleAction(rendition.availableFormats[0], 'COPY')"
                fontSet="fal"
                fontIcon="fa-copy"></mat-icon>
      <mat-icon matTooltip="Open in new tab"
                i18n-matTooltip
                (click)="handleAction(rendition.availableFormats[0], 'OPEN_NEW_TAB')"
                >launch</mat-icon>
    </ng-container>

    <!-- a case when mat-menu IS needed (many formats available) -->
    <ng-container *ngIf="rendition.availableFormats.length > 1">
      <mat-icon matTooltip="Preview image renditions" [matMenuTriggerFor]="availableFormatsMenu" i18n-matTooltip fontSet="fal" fontIcon="fa-eye" (click)="actionType = 'PREVIEW_IMAGE'"></mat-icon>
      <mat-icon matTooltip="Download image renditions" [matMenuTriggerFor]="availableFormatsMenu" i18n-matTooltip fontSet="fal" fontIcon="fa-cloud-download" (click)="actionType = 'DOWNLOAD_IMAGE'"></mat-icon>
      <mat-icon matTooltip="Copy image source"
                i18n-matTooltip
                [matMenuTriggerFor]="availableFormatsMenu"
                (click)="actionType = 'COPY'"
                fontSet="fal"
                fontIcon="fa-copy"></mat-icon>
      <mat-icon [matMenuTriggerFor]="availableFormatsMenu"
                matTooltip="Open in new tab"
                i18n-matTooltip
                (click)="actionType = 'OPEN_NEW_TAB'">launch</mat-icon>

      <mat-menu overlapTrigger="true"
                #availableFormatsMenu="matMenu"
                xPosition="after">
        <ng-container *ngFor="let format of rendition.availableFormats">
          <button mat-menu-item
                  (click)="handleAction(format)">
            {{ format.label }}
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </div>
</div>
<div class="gd-renditions-view__formats-note" i18n>* character indicates that rendition is deleted</div>
