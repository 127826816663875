import { Component, OnInit, Inject, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-image-download',
  templateUrl: './image-download.component.html',
  styleUrls: ['./image-download.component.scss']
})
export class ImageDownloadComponent implements OnInit {

  image;
  imageRef = new Image();
  imageSrc = null;

  @ViewChildren('inputField') inputField: QueryList<HTMLInputElement>;

  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<ImageDownloadComponent>,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.image = { ...this.dialogInputData.data };
    this.loadImageSource();
  }

  loadImageSource() {
    this.imageRef.src = this.image.previewImage;
    this.imageRef.onload = (event: any) => this.imageSrc = event.target.src;
  }
  
  isScaleDownRequired(image) {
    if (!this.imageRef) {
      return false;
    }
    if (this.imageRef?.width > 256) {
      return false;
    }
    return true;
  }

  updatePreview(link) {
    this.imageSrc = null;
    setTimeout(() => {
      this.imageRef.src = link;
      this.imageRef.onload = (event: any) => {
        this.imageSrc = event.target.src
      };
    }, 300);
  }
}
